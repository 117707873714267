<template>
  <div id="stops">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">Stop Management</span>
      </h2>
    </div>
    <v-divider class="mt-4 mb-10"></v-divider>
    <v-card>
      <v-row class="pt-4 pb-0 ma-0">
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqName"
            label="Stop name"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqName')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqCode"
            label="Stop code"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqCode')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqZone"
            label="Stop zone"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqZone')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="sqCustomName"
            label="Custom stop name"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqCustomName')"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqTransfers"
            :items="boolItems"
            item-text="text"
            item-value="value"
            label="Transfers"
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqTransfers')"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sqAdditionalInfo"
            :items="boolItems"
            item-text="text"
            item-value="value"
            label="Additional Info."
            outlined
            hide-details
            dense
            class="user-search me-3"
            @change="val => addToSearchHistory(val, 'sqAdditionalInfo')"
          >
          </v-select>
        </v-col>
      </v-row>
      <!-- table -->
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableColumns"
            :items="filteredStops"
            :options.sync="options"
            :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
            :page.sync="page"
          >
            <template #[`item.stop_name`]="{ item }">
              <router-link
                :to="{
                  name: 'next-stop-stop-config',
                  params: { id: item.stop_id },
                }"
                class="cursor-pointer text-decoration-none"
              >
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <span class="font-weight-semibold text--primary">{{ item.stop_name }}</span>
                    <small class="text--primary">@({{ item.stop_lat }},&nbsp;{{ item.stop_lon }})</small>
                  </div>
                </div>
              </router-link>
            </template>

            <template #[`item.custom_stop_name`]="{ item }">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span class="font-weight-semibold text--primary">
                    {{ item.config.stop_tts || "-" }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.transfers`]="{ item }">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span
                    :class="['font-weight-semibold', item.config.transfers_enabled ? 'success--text' : 'error--text']"
                  >
                    {{ item.config.transfers_enabled ? "Yes" : "No" }}
                  </span>
                </div>
              </div>
            </template>

            <template #[`item.additional_info`]="{ item }">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span
                    :class="[
                      'font-weight-semibold',
                      item.config.additional_text || item.config.additional_tts ? 'success--text' : 'error--text',
                    ]"
                  >
                    {{ item.config.additional_text || item.config.additional_tts ? "Yes" : "No" }}</span
                  >
                </div>
              </div>
            </template>

            <template #[`item.stop_id`]="{ item }">
              <div class="d-flex">
                <div class="d-flex flex-column">
                  <span class="text-capitalize font-weight-semibold text--primary">{{ item.stop_id }}</span>
                  <small v-if="item.parent_station">, Parent Station:&nbsp;{{ item.parent_station }}</small>
                </div>
              </div>
            </template>

            <template #[`item.zone_id`]="{ item }">
              <span class="text-capitalize font-weight-semibold text--primary">{{ item.zone_id }}</span>
            </template>

            <template #[`item.config`]="{ item }">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      :to="{
                        name: 'next-stop-stop-config',
                        params: { id: item.stop_id },
                      }"
                      v-on="on"
                    >
                      <v-icon size="18" color="primary">
                        {{ icons.mdiSquareEditOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit configuration for stop {{ item.stop_id }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <CSVExportButton
        :sort-by="options.sortBy[0]"
        :sort-desc="options.sortDesc[0]"
        :items="stops"
        :column-map="stopColumnMap"
      >
      </CSVExportButton>
    </v-card>
  </div>
</template>

<script>
import { mdiCircleSlice8, mdiSquareEditOutline } from "@mdi/js"
import { mapState } from "vuex"
import CSVExportButton from "@/views/next-stop/components/CSVExportButton.vue"
import urlHelpers from "@/mixins/urlHelpers"

export default {
  name: "StopList",
  metaInfo: {
    title: "Stops",
  },
  components: {
    CSVExportButton,
  },
  mixins: [urlHelpers],
  data() {
    return {
      sqName: "",
      sqCode: "",
      sqZone: "",
      sqTransfers: "",
      sqCustomName: "",
      sqAdditionalInfo: "",
      routeFilter: [],
      options: {
        sortBy: ["stop_name"],
        sortDesc: [false],
      },
      tableColumns: [
        { text: "Name", value: "stop_name" },
        { text: "Custom stop name", value: "custom_stop_name", sortable: false },
        { text: "Transfers", value: "transfers", sortable: false },
        { text: "Additional Info.", value: "additional_info", sortable: false },
        { text: "Code", value: "stop_id" },
        { text: "Zone", value: "zone_id" },
        { text: "Edit", value: "config", sortable: false },
      ],
      stopColumnMap: [
        {
          text: "Name",
          value: "stop_name",
          customFunc: "getRichStopName",
          additionalValue: "stop_lat",
          additionalValue2: "stop_lon",
        },
        {
          text: "Custom stop name",
          value: "config.stop_tts",
          conversionFunc: "emptyNice",
          emptyTrueDefault: "-",
        },
        { text: "Transfers", value: "config.transfers_enabled", conversionFunc: "boolNice" },
        {
          text: "Additional Info.",
          value: "config.additional_text",
          customFunc: "getAdditionalInfo",
          additionalValue: "config.additional_tts",
        },
        { text: "Code", value: "stop_id", customFunc: "getRichStopID", additionalValue: "parent_station" },
        { text: "Zone", value: "zone_id" },
      ],
      icons: {
        mdiCircleSlice8,
        mdiSquareEditOutline,
      },
      boolItems: [
        {
          text: "",
          value: "",
        },
        {
          text: "Yes",
          value: 1,
        },
        {
          text: "No",
          value: 0,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      stops: state => state.gtfs.stops,
      routes: state => state.gtfs.routes,
    }),

    filteredStops() {
      const { sortBy = [], sortDesc = [] } = this.options

      let filtered = this.stops.filter(stop => {
        let include = true

        if (include && this.sqName.length) {
          include = stop.stop_name.toLowerCase().includes(this.sqName.toLowerCase())
        }

        if (include && this.sqCode.length) {
          include = stop.stop_code.toLowerCase().includes(this.sqCode.toLowerCase())
        }

        if (include && this.sqZone.length) {
          include = stop.zone_id.toLowerCase().includes(this.sqZone.toLowerCase())
        }

        if (include && this.sqCustomName.length) {
          include = stop.config.stop_tts.toLowerCase().includes(this.sqCustomName.toLowerCase())
        }

        if (include && this.sqTransfers !== "") {
          include = !!stop.config.transfers_enabled === !!this.sqTransfers
        }

        if (include && this.sqAdditionalInfo !== "") {
          include = !!(stop.config.additional_text || stop.config.additional_tts) === !!this.sqAdditionalInfo
        }

        return include
      })

      if (this.routeFilter.length) {
        filtered = filtered.filter(stop => stop.routes.find(r => this.routeFilter.includes(r)))
      }

      // sorting
      if (sortBy.length === 1 && sortDesc.length === 1) {
        filtered = filtered.sort((a, b) => {
          let sortA = a[sortBy[0]]
          let sortB = b[sortBy[0]]

          if (sortBy[0] === "zone_id") {
            sortA = parseFloat(sortA.split("/").join("."))
            sortB = parseFloat(sortB.split("/").join("."))
          }

          if (sortA > sortB) return 1
          if (sortA < sortB) return -1

          return 0
        })
      }

      return filtered
    },
    routeOptions() {
      return this.routes.map(route => ({
        value: route.route_id,
        title: `${route.route_short_name} - ${route.route_long_name}`,
      }))
    },
  },
}
</script>
